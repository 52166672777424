import React, { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { Routes, Route, Link } from "react-router-dom";
import "../styles/UserDashboard.css";
import Dashboard from "./Dashboard";
import Orders from "./Orders";
import Downloads from "./Downloads";
import Addresses from "./Addresses";
import AccountDetails from "./AccountDetails";

const UserDashboard = () => {
  const { logout } = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="userDashboardContainer">
      <div className="userDashboardContainerHeader">
        <div className="userDashboardContainerHeaderTop">
          <div className="rectangle"></div>
          <p>My account</p>
        </div>
      </div>
      <div className="userDashboardMain">
        <div className="userDashboardMainLeft">
          <ul>
            <li>
              <Link to="dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="orders">Orders</Link>
            </li>
            <li>
              <Link to="downloads">Downloads</Link>
            </li>
            <li>
              <Link to="addresses">Addresses</Link>
            </li>
            <li>
              <Link to="account-details">Account Details</Link>
            </li>
            <li onClick={() => logout()}>Log Out</li>
          </ul>
        </div>
        <div className="userDashboardMainRight">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="orders" element={<Orders />} />
            <Route path="downloads" element={<Downloads />} />
            <Route path="addresses" element={<Addresses />} />
            <Route path="account-details" element={<AccountDetails />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
