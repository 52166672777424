import React, { useEffect } from "react";
import "../styles/Dhyanam.css";
import first from "../assets/first.jpeg";
import second_one from "../assets/second_one.png";
import second_two from "../assets/second_two.jpeg";
import creator from "../assets/first.jpeg";

import Significance from "../assets/Significance.jpeg";
import joy from "../assets/joy.jpeg";
import burning from "../assets/burning.jpeg";
import sixth from "../assets/sixth.png";
import absolute from "../assets/second_two.jpeg";
import temp from "../assets/temp.png";
import temp2 from "../assets/temp2.png";
import temp3 from "../assets/temp3.png";

import { useNavigate } from "react-router-dom";

const Dhyanam = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dhyanamMainContainer">
      <h2 onClick={() => navigate("/dhyanamshop")} className="dhyanamShopText">
        Dhyanam Shop
      </h2>
      <div className="dhyanamContainerFirst">
        <h1>Dhyanam</h1>
        <p>By Dr.Ravi Shankar</p>
        <img src={first} alt="image" />
      </div>
      <div className="dhyanamContainerSecond">
        <div className="dhyanamContainerSecondTop">
          <div className="dhyanamContainerSecondTopLeft">
            <img src={second_one} alt="image" />
          </div>
          <div className="dhyanamContainerSecondTopRight">
            <p>Introduction</p>
            <h1>About Me</h1>
          </div>
        </div>
        <div className="dhyanamContainerSecondBottom">
          <p>
            Dr. Ravi Shankar, a former civil servant who has transformed his
            career into that of a motivational speaker and life coach. With
            extensive experience in various administrative roles, Dr. Shankar
            now dedicates his life to guiding individuals seeking personal and
            professional growth. His journey reflects a commitment to service,
            empowerment, and continuous learning.
          </p>
          <p>
            Dr. Shankar believes in the immense potential within every
            individual. His holistic approach to coaching nurtures the mind,
            body, and spirit, focusing on sustainable transformation rather than
            temporary motivation. He envisions a supportive community where
            individuals uplift one another, fostering collective well-being.
          </p>
          <p>
            Throughout his career, Dr. Shankar has achieved remarkable
            milestones, including leading innovative skill development
            initiatives and improving operational efficiencies in various
            capacities. His dedication to community welfare and environmental
            conservation
          </p>
          <img src={second_two} alt="image" />
        </div>
      </div>

      <div className="dhyanamContainerThird">
        <p className="noteFromArtist">Note from the artist</p>
        <h1>Why did I choose Mandala?</h1>
        <p>
          The shapes of Mandala, repeating shapes and the tendency of growing
          outwards from the centre in the evolutionary order have probably been
          mesmerizing since the beginning of creation. This is the reason that
          despite the requests of caste, religion, civilization, culture,
          language, Mandala is found in all the cultures of the world.
        </p>
        <p>
          From the beginning, the changing shapes of white clouds in the blue
          sky, the changing images of waves while sitting on the banks of the
          river, the colors of flowers, all used to mesmerize me. Painting was a
          hobby since childhood and became a profession when I grew up a bit.
          Later, after joining the civil services, there was a long period of
          silence but after retirement, the dormant interest in painting called
          out again but one change was that earlier the painter's mind wanted to
          see only beauty in paintings but now it also wanted to feel peace,
          gentleness and divine presence in the shapes.
        </p>
        <img src={second_two} alt="image" />
      </div>

      <div className="dhyanamContainerForth">
        <h1>Significance of Mandala</h1>
        <p>
          The movement of everything present in the universe is circular or
          cyclic. Vedic wisdom has also considered time to be cyclic.
        </p>
        <p>
          If we pay attention, the movement of all that is natural and important
          in human life is cyclic.
        </p>
        <p>
          That is why both time and the universe are called infinite, because a
          circle is a shape that has neither a starting point nor an ending
          point, the truth is that the circumference of a circle itself is a
          series of infinite points mathematically.
        </p>
        <p>
          This is the shape that must have been outlined by man first, because
          it is the simplest shape that can be made by a line.
        </p>
        <p>
          That is why the circle and the shape formed by it are so important.
          Because it is the exact symbol of the origin point and the entire
          universe including human life.
        </p>
        <p>
          Mandalas are created by using many shapes, patterns and symbols in
          this circle. Mandalas were first used in which culture?
        </p>
        <p>
          It is almost impossible to determine this. But in almost all the
          ancient cultures of the world including India, Mandala has been used
          as a worldly decoration and spiritual practice.
        </p>
        <p>
          In Indian contexts, the words Mandala and Mandalakar are also used in
          the Vedas. There is sufficient evidence of its use in ancient Sanatan
          culture for worship, adoration and meditation. Apart from the Indian
          Sanatan tradition, Mandala art has been used for meditation since
          ancient times in Buddhism, Jainism, Tibet and China. Although it is
          still used for worship in all civilizations including Vedic, Jain,
          Buddhist, Shintoism, Persian, Mesopotamian, Native American, Celtic
          etc., but today historians believe that its first origin is in the
          Vedas (Rigveda).
        </p>
        <p>
          Mandala is a very effective tool for meditation. Its geometric shapes,
          patterns and images, which increase from the center to the periphery,
          make it effective for increasing concentration and reaching a
          meditative state. In modern times, mandala is drawn in many shapes,
          but traditionally it is in the form of a circle.
        </p>
        <img src={Significance} alt="image" />
      </div>

      <div className="dhyanamContainerFifth">
        <h1>The Creator</h1>
        <img src={creator} alt="image" />
        <p className="creatorP">
          "The piece emits the thought of creation of this universe which
          originates from OM and spread beauty, love and enthusiasm till
          eternity."
        </p>
      </div>
      <div className="dhyanamContainerSixth">
        <img src={sixth} alt="image" />
      </div>

      <div className="dhyanamContainerFifth">
        <h1>Absolute</h1>
        <img src={absolute} alt="image" />
        <p>
          "The art symbolises a complete and fulfilling thought which is
          absolute and eternal."
        </p>
      </div>

      <div className="dhyanamContainerSixth">
        <img src={temp} alt="image" />
      </div>

      <div className="dhyanamContainerFifth">
        <h1>Joy Of Life</h1>
        <img src={joy} alt="image" />
        <p>
          "It's creation depicts the joy which life carries in form people
          dancing, the pleasant sound of dhol and the brave soldiers."
        </p>
      </div>

      <div className="dhyanamContainerSixth">
        <img src={temp2} alt="image" />
      </div>

      <div className="dhyanamContainerFifth">
        <h1>Burning Desires</h1>
        <img src={burning} alt="image" />
        <p>
          "It focuses on the emotion of desires one the strongest forces to
          exist.In this piece women are performing pre war rituals and men are
          getting ready with their spears for the battle which has come along
          their door."
        </p>
      </div>

      <div className="dhyanamContainerSixth">
        <img src={temp3} alt="image" />
      </div>

      <div className="lastSectionContainer">
        <h1>Order and Pricing</h1>
        <h2>Handmade Custom Order</h2>
        <p>
          Pricing for custom handmade mandalas will be quoted based on the
          specific needs of each order, such as size, complexity, and materials
          used.
        </p>
        <h2>Digital Prints</h2>
        <p>
          Pricing for custom digital mandala designs will be quoted based on the
          specific needs of each order.
        </p>
        <h2>Pre-made Digital Prints</h2>
        <p>
          Pricing for pre-made digital mandala designs will be based on the size
          and complexity of the artwork.
        </p>
        <p className="disclamer">
          *Current maximum size available for digital prints: 20 inches x 20
          inches.
        </p>
        <h3>How Do I Order?</h3>
        <p>
          To place your order, please contact us through Call or WhatsApp at:
        </p>
        <h4>Phone / WhatsApp: 9454999000, 9450924888</h4>
        <p className="disclamer">
          I'm happy to discuss your ideas and create a unique piece of mandala
          art that meets your needs. Please feel free to reach out with any
          questions or to request a personalized quote
        </p>
      </div>
    </div>
  );
};

export default Dhyanam;
