import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Home.css";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const YoutubeVideoCarousel = () => {
  const [videos, setVideos] = useState([]);
  const API_KEY = "AIzaSyCM9fBkE8GFwo1UPX3LpHUTzkvxaDJz8mA";
  const CHANNEL_ID = "UCFHQXjaQAQFzG_zdGimKwOQ";

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=10`
        );
        const data = await response.json();
        console.log(data);
        setVideos(data.items);
      } catch (error) {
        console.error("Error fetching YouTube videos:", error);
      }
    };
    fetchVideos();
  }, []);

  // Custom Arrow Components
  const NextArrow = ({ onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
        width: "50px", // Increase width
        height: "50px", // Increase height
        borderRadius: "50%",
        position: "absolute",
        top: "50%", // Center vertically
        right: "10px", // Adjust position from right
        transform: "translateY(-50%)", // Center vertically
        cursor: "pointer",
        zIndex: 1, // Ensure it's on top
      }}
      onClick={onClick}
    >
      <IoIosArrowForward color="white" size={25} />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "black",
        width: "50px", // Increase width
        height: "50px", // Increase height
        borderRadius: "50%",
        position: "absolute",
        top: "50%", // Center vertically
        left: "4px", // Adjust position from left
        transform: "translateY(-50%)", // Center vertically
        cursor: "pointer",
        zIndex: 1, // Ensure it's on top
      }}
      onClick={onClick}
    >
      <IoIosArrowBack color="white" size={25} />
    </div>
  );

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="youtubeVideoCarousel" style={{ position: "relative" }}>
      {videos && videos.length > 0 ? (
        <Slider {...settings}>
          {videos.map((video) => (
            <div key={video.id.videoId}>
              <iframe
                width="98%"
                height="315"
                src={`https://www.youtube.com/embed/${video.id.videoId}`}
                title={video.snippet.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p style={{ color: "red" }}>
          Something went wrong. Please try again later.
        </p>
      )}
    </div>
  );
};

export default YoutubeVideoCarousel;
