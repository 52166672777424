import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/PaymentStatus.css";
import failure from "../assets/failure.gif";

const PaymentFailure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="paymentStatusContainer">
      <img src={failure} alt="failure" />
      <h1>Payment Failed!</h1>
      <p>
        Your payment could not be processed. Please try again or contact
        support.
      </p>
      <p>Redirecting...</p>
    </div>
  );
};

export default PaymentFailure;
