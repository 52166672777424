import React, { useState, useEffect, useContext } from "react";
import "../styles/Library.css";
import { AuthContext } from "../AuthContext";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

const Library = () => {
  const [books, setBooks] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetch("https://api.lifesignify.com/api/books/");
        if (!response.ok) {
          throw new Error("Failed to fetch books");
        }
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBooks();
  }, []);

  const addToCart = async (bookId) => {
    if (!isLoggedIn) {
      alert("Please log in to add items to your cart.");
      return;
    }

    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    setLoadingId(bookId);

    try {
      const itemType = "Book";
      const response = await fetch("https://api.lifesignify.com/api/cart/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          productId: bookId,
          quantity: 1,
          userId,
          itemType: itemType,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add book to cart");
      }

      const cartData = await response.json();
      alert("Book added to cart successfully!");
      navigate("/cart");
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setLoadingId(null);
    }
  };

  return (
    <div className="libraryContainer">
      <div className="libraryHeader">
        <div className="libraryHeaderTop">
          <div className="rectangle"></div>
          <p>Library</p>
        </div>
        <div className="libraryHeaderBottom">
          <h1>
            Discover a curated selection of books to expand your knowledge and
            growth.
          </h1>
        </div>
      </div>
      <div className="libraryBooksContainer">
        {books.length > 0 ? (
          books.map((book) => (
            <div className="bookDiv" key={book._id}>
              <div className="bookDivTop">
                <img src={book.imageUrl} alt="book" />
              </div>
              <div className="bookDivBottom">
                <h2 className="bookTitle">{book.title}</h2>
                <p className="bookCost">₹{book.price}</p>
                <button
                  className="BookDownloadOrBuyBtn"
                  onClick={() => {
                    if (book.price === "Free") {
                      window.open(book.pdfLink, "_blank");
                    } else {
                      addToCart(book._id);
                    }
                  }}
                >
                  {book.price === "Free" ? "Download Now" : "Buy Now"}
                </button>
              </div>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Library;
