import React, { useEffect, useState } from "react";
import "../styles/Team.css";
import { useNavigate } from "react-router-dom";

const Team = () => {
  const [team, setTeam] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await fetch(`https://api.lifesignify.com/api/teams`);
        if (!response.ok) {
          throw new Error("Team member not found");
        }
        const data = await response.json();
        setTeam(data);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    fetchMember();
  }, []);

  console.log(team);

  const leaders = [
    {
      id: 1,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Dr. Ravi Shankar",
      designation: "CEO",
    },
    {
      id: 2,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Leader Two",
      designation: "CTO",
    },
  ];

  const subLeaders = [
    {
      id: 3,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Sub Leader One",
      designation: "Manager",
    },
    {
      id: 4,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Sub Leader Two",
      designation: "Manager",
    },
    {
      id: 5,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Sub Leader Three",
      designation: "Manager",
    },
  ];

  const supportingTeam = [
    {
      id: 6,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Support One",
      designation: "Developer",
    },
    {
      id: 7,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Support Two",
      designation: "Designer",
    },
    {
      id: 8,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Support Three",
      designation: "Analyst",
    },
    {
      id: 9,
      image:
        "http://localhost:3000/static/media/family.1a2a774069bda3e21635.jpg",
      name: "Support Four",
      designation: "Marketer",
    },
  ];

  const handleCardClick = (id) => {
    navigate(`/team/${id}`);
  };

  // memberType :- leader -

  return (
    <div className="teamContainer">
      <h2>Leadership</h2>
      <div className="leaders">
        {team
          .filter((leader) => leader.memberType === "leader")
          .map((leader) => (
            <div
              key={leader.id}
              className="leaderCard"
              onClick={() => handleCardClick(leader._id)}
            >
              <img src={leader.image} alt={leader.name} />
              <h3>{leader.name}</h3>
              <p>{leader.designation}</p>
            </div>
          ))}
      </div>

      <h2>Expert Team</h2>
      <div className="subLeaders">
        {team
          .filter((leader) => leader.memberType === "expert")
          .map((subLeader) => (
            <div
              key={subLeader.id}
              className="subLeaderCard"
              onClick={() => handleCardClick(subLeader._id)}
            >
              <img src={subLeader.image} alt={subLeader.name} />
              <h3>{subLeader.name}</h3>
              <p>{subLeader.designation}</p>
            </div>
          ))}
      </div>

      <h2>Support Team</h2>
      <div className="supportingTeam">
        {team
          .filter((leader) => leader.memberType === "support")
          .map((member) => (
            <div
              key={member.id}
              className="supportingTeamCard"
              onClick={() => handleCardClick(member._id)}
            >
              <img src={member.image} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.designation}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Team;
