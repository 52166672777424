import React, { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Courses from "./Courses";
import Library from "./Library";
import GetAConsultation from "./GetAConsultation";
import Login from "./Login";
import HooponoponoPowerWebinar from "./HooponoponoPowerWebinar";
import AboutUs from "./AboutUs";
import DrRaviShankar from "./DrRaviShankar";
import ArchanaShankarGupta from "./ArchanaShankarGupta";
import DrAshishMaheshGupta from "./DrAshishMaheshGupta";
import PremlataSaxena from "./PremlataSaxena";
import PankajTripathi from "./PankajTripathi";
import UserDashboard from "./UserDashboard";
import TeamDetails from "./TeamDetails";

// showcases components
import DMIT from "../components/showcase/DMIT";
import LawOfAttraction from "../components/showcase/LawOfAttraction";
import PersonalityDevelopment from "../components/showcase/PersonalityDevelopment";
import MidbrainActivations from "../components/showcase/MidbrainActivations";
import WealthManagement from "../components/showcase/WealthManagement";
import FinancialPlanning from "../components/showcase/FinancialPlanning";
import Dhyanam from "../components/Dhyanam";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailure from "./PaymentFailure";
import Team from "./Team";
import Testimonial from "./Testimonial";
import DhyanamShop from "./DhyanamShop";

const Content = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/library" element={<Library />} />
      <Route path="/get-a-consultation" element={<GetAConsultation />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failure" element={<PaymentFailure />} />
      <Route path="/team/:id" element={<TeamDetails />} />
      <Route path="/dhyanamshop" element={<DhyanamShop />} />

      <Route
        path="/my-account/*"
        element={isLoggedIn ? <UserDashboard /> : <Login />}
      />

      <Route path="/hooponopono-power" element={<HooponoponoPowerWebinar />} />
      <Route path="/dhyanam" element={<Dhyanam />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/team" element={<Team />} />
      <Route path="/testimonial" element={<Testimonial />} />
      <Route path="/dr-ravi-shankar" element={<DrRaviShankar />} />
      <Route path="/archana-shankar-gupta" element={<ArchanaShankarGupta />} />
      <Route path="/dr-ashish-mahesh-gupta" element={<DrAshishMaheshGupta />} />
      <Route path="/premlata-saxena" element={<PremlataSaxena />} />
      <Route path="/pankaj-tripathi" element={<PankajTripathi />} />

      {/* ====================== Showcase Routes ====================== */}

      <Route path="/dmit" element={<DMIT />} />
      <Route path="/law-of-attraction" element={<LawOfAttraction />} />
      <Route
        path="/personality-development"
        element={<PersonalityDevelopment />}
      />
      <Route path="/midbrain-activation" element={<MidbrainActivations />} />
      <Route path="/wealth-management" element={<WealthManagement />} />
      <Route path="/financial-planning" element={<FinancialPlanning />} />

      {/* ====================== Industries Routes ====================== */}
    </Routes>
  );
};

export default Content;
