import React, { useEffect } from "react";
import "../../styles/DMIT.css";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const LawOfAttraction = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="DMITContainer">
      <div className="DMITHeader">
        <div className="DMITHeaderTop">
          <div className="rectangle"></div>
          <p>Law of Attraction</p>
        </div>
        <div className="DMITHeaderBottom">
          <h1>Manifest Your Desires with the Law of Attraction</h1>
        </div>
      </div>
      <div className="DMITInfoContainer">
        <p>
          The Law of Attraction is a powerful principle that helps you manifest
          your desires and achieve your goals by aligning your thoughts and
          actions with your aspirations. This training program teaches you
          techniques to harness positive thinking and visualization to create a
          fulfilling life.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Benefits of Law of Attraction</p>
      </div>
      <div className="benefitsOfDMITContainer">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Goal Achievement
          </h1>
          <p>Attract and achieve personal and professional goals.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Positive Thinking
          </h1>
          <p>Cultivate a positive mindset that drives success.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Increased Motivation
          </h1>
          <p>Boost your motivation to pursue and accomplish your dreams.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Stress Reduction:
          </h1>
          <p>
            Experience lower stress levels and enhanced emotional well-being.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Personal Empowerment
          </h1>
          <p>Gain control over your life and destiny.</p>
        </div>
      </div>

      <div className="howDmitWorksContainer">
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>How Law of Attraction Works</p>
          </div>
          <p>
            The Law of Attraction involves training sessions and practices that
            focus on positive thinking and visualization. This process includes:
          </p>
          <ol>
            <li>
              <b>Mindset Training:</b>Techniques to develop a positive and
              proactive mindset.
            </li>
            <li>
              <b>Visualization Exercises:</b>Practices to vividly imagine and
              attract desired outcomes.
            </li>
            <li>
              <b>Goal Setting:</b>Strategies to clearly define and pursue your
              goals.
            </li>
            <li>
              <b>Affirmations:</b>Use of positive affirmations to reinforce
              desired beliefs and outcomes.
            </li>
          </ol>
        </div>
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>Who Can Benefit?</p>
          </div>
          <ul>
            <li>
              <b>Students:</b>Enhance academic and personal achievements.
            </li>
            <li>
              <b>Professionals:</b> Boost career success and professional
              growth.
            </li>
            <li>
              <b>Individuals:</b> Anyone looking to improve their life and reach
              their goals.
            </li>
          </ul>
        </div>
      </div>
      <div className="dmitContactUsContainer">
        <h1>Get Started with Law of Attraction</h1>
        <div>
          <p>
            Unlock your brain's full potential with Midbrain Activation
            training. Contact us today to schedule your session and
            consultation.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Contact Us Now
        </button>
      </div>
    </div>
  );
};

export default LawOfAttraction;
