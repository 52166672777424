import React, { useEffect } from "react";
import "../styles/DrRaviShankar.css";

const ArchanaShankarGupta = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="DrRaviShankarMain">
      <div className="first-sec">
        <div className="first-sec-first">
          <div className="skew-rec"></div>
          <h2 className="first-h2">Archana Shankar Gupta</h2>
        </div>
        <div className="first-sec-sec">
          <h1 className="f-h2">
            A seasoned financial expert dedicated to guiding clients towards
            financial stability and growth.
          </h1>
        </div>
      </div>
      <div className="second-sec">
        <div className="sec-first">
          <div className="sec-first-first">
            <h2>
              Archana Shankar Gupta is a seasoned financial expert with a
              passion for empowering clients to achieve their financial goals.
              With a deep understanding of wealth management and financial
              planning, she provides personalized strategies to optimize returns
              and manage risks effectively. Her expertise encompasses portfolio
              management, asset allocation, and risk management, ensuring
              comprehensive financial health. Archana’s dedication to her
              clients’ success is evident in her tailored investment,
              retirement, and education planning services. She is committed to
              guiding individuals and families towards a secure and prosperous
              future.
            </h2>
          </div>
          <div className="sec-first-second">
            <img src="https://lifesignify.com/wp-content/uploads/2024/05/Archana-maam-.jpg" />
          </div>
        </div>
        <div className="sec-sec">
          <div className="third-section">
            <div className="third-sec-first"></div>
            <div className="third-sec-sec">
              <div className="third-sec-sec-first">
                <div>
                  <div className="skew-rec"></div>
                  <h2 className="third-sec-sec-first-h2">Wealth Management</h2>
                </div>
                <h1 className="third-sec-sec-first-h1">Wealth Management</h1>
                <p className="third-sec-sec-first-h3">
                  Archana Shankar Gupta offers a holistic approach to wealth
                  management, focusing on optimizing your financial health. Her
                  portfolio management services aim to maximize returns while
                  effectively managing risks through tailored strategies. By
                  utilizing strategic asset allocation, she diversifies
                  investments to balance risk and reward. Additionally, Archana
                  provides comprehensive risk management solutions, including
                  insurance and other measures, to protect your assets and
                  ensure financial stability.
                </p>
              </div>
              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">
                  Portfolio Management
                </h2>
                <p className="third-sec-sec-second-p">
                  Active management to optimize returns while effectively
                  managing risk. Tailored strategies to meet individual
                  financial goals.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Asset Allocation</h2>
                <p className="third-sec-sec-second-p">
                  Maximize growth potential through diversified asset
                  allocation. Strategic distribution of investments to balance
                  risk and reward.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Risk Management</h2>
                <p className="third-sec-sec-second-p">
                  Comprehensive strategies to manage financial risks. Includes
                  insurance solutions and other measures to protect assets.
                </p>
              </div>

              <div className="third-sec-sec-first1">
                <div>
                  <div className="skew-rec"></div>
                  <h2 className="third-sec-sec-first-h2">Financial Planning</h2>
                </div>
                <h1 className="third-sec-sec-first-h1">Financial Planning</h1>
                <p className="third-sec-sec-first-h3">
                  Archana Shankar Gupta’s financial planning services are
                  designed to guide you toward long-term financial success. She
                  offers personalized investment planning to help grow your
                  wealth and achieve your financial objectives. Her retirement
                  planning services provide detailed strategies for securing a
                  comfortable future, encompassing savings plans and income
                  management. Archana also specializes in education planning,
                  offering expert guidance on saving and investing for future
                  educational expenses, ensuring you are financially prepared
                  for academic needs.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Investment Planning</h2>
                <p className="third-sec-sec-second-p">
                  Customized investment strategies to grow your wealth. Focused
                  on achieving long-term financial objectives.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Retirement Planning</h2>
                <p className="third-sec-sec-second-p">
                  Detailed plans to secure a comfortable retirement. Includes
                  savings plans, investment strategies, and income management.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Education Planning</h2>
                <p className="third-sec-sec-second-p">
                  Expert guidance on saving and investing for educational
                  expenses. Ensures financial preparedness for future academic
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchanaShankarGupta;
