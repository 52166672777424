import React, { useState, useEffect } from "react";
import "../styles/Addresses.css";
import { MdDelete } from "react-icons/md";

const Addresses = () => {
  const userId = localStorage.getItem("userId");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    name: "",
    mobile: "",
    street: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/auth/user/addresses/${userId}`
      );
      const data = await response.json();
      setAddresses(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleAddClick = () => {
    setIsFormVisible(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({
      ...newAddress,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/auth/address",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            ...newAddress,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add address");
      }

      const addedAddress = await response.json();
      fetchAddresses();
      setAddresses([...addresses, addedAddress]);
      setIsFormVisible(false);
      alert("Address successfully added!");
      setNewAddress({
        name: "",
        mobile: "",
        street: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
      });
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  const handleDelete = async (addressId) => {
    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/auth/address/${addressId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete address");
      }
      alert("Address successfully deleted!");
      fetchAddresses();
      setAddresses(addresses.filter((address) => address._id !== addressId));
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  const handleCloseClick = () => {
    setIsFormVisible(false);
  };

  return (
    <div className="addressesContainer">
      <p>
        The following addresses will be used on the checkout page by default.
      </p>
      <div className="addressesContainerMain">
        <div className="addressesContainerMainLeft">
          <div>
            <h1>Shipping address</h1>
            <p onClick={handleAddClick}>Add</p>
          </div>
          {addresses.length === 0 && (
            <p>You have not set up this type of address yet.</p>
          )}
        </div>

        {addresses && (
          <div className="addressCard">
            {Array.isArray(addresses) &&
              addresses.map((address) => (
                <div key={address._id} className="addressCardContent">
                  <p>{address.name}</p>
                  <p>{address.mobile}</p>
                  <p>{address.street}</p>
                  <p>{address.city}</p>
                  <p>{address.state}</p>
                  <p>{address.country}</p>
                  <p>{address.pincode}</p>
                  <p
                    className="delete"
                    onClick={() => handleDelete(address._id)}
                  >
                    <MdDelete />
                  </p>
                </div>
              ))}
          </div>
        )}

        {isFormVisible && (
          <div className="addressForm">
            <h2>Add New Address</h2>
            <button className="closeButton" onClick={handleCloseClick}>
              X
            </button>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={newAddress.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="mobile"
                placeholder="Mobile"
                value={newAddress.mobile}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="street"
                placeholder="Street/Landmark"
                value={newAddress.street}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="country"
                placeholder="Country"
                value={newAddress.country}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={newAddress.state}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="city"
                placeholder="City"
                value={newAddress.city}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="pincode"
                placeholder="Pincode"
                value={newAddress.pincode}
                onChange={handleChange}
                required
              />
              <button type="submit">Add</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Addresses;
