import Footer from "./components/Footer";
import Header from "./components/Header";
import Content from "./components/Content";
import { useLocation, useNavigate } from "react-router-dom";

import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import { IoMdArrowDropright } from "react-icons/io";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const hideHeaderFooterRoutes = ["/cart", "/checkout"];

  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(
    location.pathname
  );

  const handleWhatsAppClick = () => {
    const phoneNumber = "919454999000";
    const message = encodeURIComponent(
      "Hello Life Signify,\n\nI want to know more about DMIT.\n\nPage Title: Life Signify - From Personal Growth to Financial Mastery\nPage URL: https://lifesignify.com/"
    );
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, "_blank");
  };

  return (
    <div className="App">
      {!shouldHideHeaderFooter && <Header />}
      {!(
        location.pathname === "/cart" || location.pathname === "/checkout"
      ) && (
        <>
          <div onClick={() => navigate("/dmit")} className="dmit_button_fixed">
            DMIT
            <IoMdArrowDropright size={28} />
          </div>
          <div className="whatsapp_icon" onClick={handleWhatsAppClick}>
            <img
              src="https://imgs.search.brave.com/Zyt-8y4aIdTJaAksAhVwyy_yYJ1QPisms32tLfftN28/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdHls/ZXMucmVkZGl0bWVk/aWEuY29tL3Q1XzJ0/NHA3L3N0eWxlcy9j/b21tdW5pdHlJY29u/X2dzbGpuc3FjZDJr/MzEucG5n"
              alt="whatsapp"
            />
          </div>
        </>
      )}
      <Content />
      {location.pathname === "/cart" && <Cart />}
      {location.pathname === "/checkout" && <Checkout />}
      {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
