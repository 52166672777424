import React, { useState, useContext, useEffect } from "react";
import "../styles/Login.css";
import { AuthContext } from "../AuthContext";
import { FaCheck } from "react-icons/fa6";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { login } = useContext(AuthContext); // Use the AuthContext
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);

  const sendOtp = async () => {
    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/auth/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: registerEmail }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send OTP");
      }

      setOtpSent(true);
      setOtpValidated(false);
      alert("OTP sent successfully!");
    } catch (error) {
      console.error(error);
    }
  };

  const validateOtp = async () => {
    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/auth/validate-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: registerEmail, otp }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Invalid OTP");
      }

      setOtpValidated(true);
      alert("OTP validated successfully!");
    } catch (error) {
      alert(error.message);
    }
  };

  const loginUser = async (email, password) => {
    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const data = await response.json();
      alert("Successfully logged in!");
      login(data.user.userId);
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    loginUser(loginEmail, loginPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!otpValidated) {
      alert("Please validate your OTP first.");
      return;
    }

    try {
      const response = await fetch(
        "https://api.lifesignify.com/api/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: registerEmail,
            password: registerPassword,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Registration failed");
      }

      await loginUser(registerEmail, registerPassword);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginContainerHeader">
        <div className="loginContainerHeaderTop">
          <div className="rectangle"></div>
          <p>My account</p>
        </div>
      </div>
      <div className="registerLoginFormContainer">
        <div>
          <h1>Login</h1>
          <form onSubmit={handleLogin} className="loginFormContainer">
            <label>
              Email address <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              required
            />
            <label>
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              required
            />
            <div className="rememberMeDiv">
              <input className="checkboxRememberme" type="checkbox" />
              <p>Remember me</p>
            </div>
            <button type="submit">Log in</button>
            <p className="lostYouPassword">Lost your password?</p>
          </form>
        </div>
        <div>
          <h1>Register</h1>
          <form onSubmit={handleRegister} className="registerFormContainer">
            <label>
              Email address <span style={{ color: "red" }}>*</span>
              {otpValidated && (
                <p
                  style={{
                    color: "#90EE90",
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 5,
                  }}
                >
                  Email verified successfully!
                  <FaCheck
                    color="#90EE90"
                    size={22}
                    style={{ marginLeft: 5 }}
                  />
                </p>
              )}
            </label>
            <input
              type="email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              required
            />
            {otpSent && !otpValidated ? (
              <>
                <label>
                  Enter OTP <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button type="button" onClick={validateOtp}>
                  Validate OTP
                </button>
              </>
            ) : null}
            {!otpSent ? (
              <button type="button" onClick={sendOtp}>
                Send OTP
              </button>
            ) : null}
            <label>
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
              required
            />
            <p>
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our{" "}
              <span style={{ color: "red" }}>privacy policy</span>.
            </p>
            <button type="submit" disabled={!otpValidated}>
              Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
