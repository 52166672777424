import React, { useState, useEffect, useContext } from "react";
import "../styles/AccountDetails.css";
import { AuthContext } from "../AuthContext";

const AccountDetails = () => {
  const { userData } = useContext(AuthContext);
  const [firstName, setFirstName] = useState(userData.firstName || "");
  const [lastName, setLastName] = useState(userData.lastName || "");
  const [displayName, setDisplayName] = useState(userData.displayName || "");

  const userId = localStorage.getItem("userId");

  const handleSaveChanges = async () => {
    const updatedData = {
      firstName,
      lastName,
      displayName,
    };

    try {
      const response = await fetch(
        `https://api.lifesignify.com/api/auth/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Account details updated successfully!");
      } else {
        const error = await response.json();
        alert(`Error: ${error.message}`);
      }
    } catch (error) {
      alert("Network error. Please try again later.");
    }
  };

  useEffect(() => {
    setFirstName(userData.firstName || "");
    setLastName(userData.lastName || "");
    setDisplayName(userData.displayName || "");
  }, [userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="account-details">
      <div className="input-group">
        <div>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
      </div>

      <label htmlFor="displayName">Display Name</label>
      <input
        type="text"
        id="displayName"
        className="full-width"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        required
      />

      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        className="full-width"
        value={userData?.email}
        readOnly
      />

      <p>Password change</p>

      <label htmlFor="newPassword">
        Current password (leave blank to leave unchanged)
      </label>
      <input type="password" id="newPassword" className="full-width" required />
      <label htmlFor="newPassword">
        New password (leave blank to leave unchanged)
      </label>
      <input
        type="password"
        id="confirmNewPassword"
        className="full-width"
        required
      />

      <label htmlFor="confirmPassword">Confirm new password</label>
      <input
        type="password"
        id="confirmPassword"
        className="full-width"
        required
      />

      <button className="save-button" onClick={handleSaveChanges}>
        Save Changes
      </button>
    </div>
  );
};

export default AccountDetails;
