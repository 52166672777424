import React, { useEffect } from "react";
import "../../styles/WealthAndFinancial.css";
import { FaCheck } from "react-icons/fa";
import { BiPieChartAlt2 } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { PiShieldWarningFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const WealthManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="wealthAndManagementContainer">
      <div className="wealthAndManagementHeader">
        <div className="wealthAndManagementHeaderTop">
          <div className="rectangle"></div>
          <p>Wealth Management</p>
        </div>
        <div className="wealthAndManagementHeaderBottom">
          <h1>Finding the Right Advisor for Your Path</h1>
        </div>
      </div>
      <div className="wealthAndManagementInfoContainer">
        <p>
          We delve into creating a secure and prosperous financial future
          tailored to your unique needs. Our holistic approach encompasses
          comprehensive strategies that not only aim to grow your wealth but
          also safeguard it against market volatilities. We focus on
          personalized solutions that align with your long-term objectives,
          ensuring that your financial legacy is built on a foundation of sound
          investment principles and stewardship.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Our Mission</p>
      </div>
      <div className="ourMissionWealthAndManagement">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Developing
          </h1>
          <p>
            The core reason behind our wealth management services is to create a
            solid foundation for your financial future.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Understanding
          </h1>
          <p>
            By understanding your situation, goals, and experience, we tailor
            investments for your wealth goals.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Financial plan
          </h1>
          <p>
            We conduct annual reviews to update your financial plan and address
            related matters like retirement planning, insurance, tax-efficiency
            etc.
          </p>
        </div>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Our Capabilities</p>
      </div>
      <div className="managementSolutionContainer">
        <div className="managementSolutionHeading">
          <h1>Specialized Wealth Management Solutions</h1>
        </div>
        <div className="serviceOptions">
          <div className="servicesDiv">
            <GoGraph color="white" size={50} />
            <h3>Portfolio Management</h3>
            <p>
              With an expansive investment opportunity set encompassing stocks,
              bonds, real estate, and commodities, our commitment to effective
              diversification extends beyond capital allocation across diverse
              asset classes. We also meticulously diversify across various
              economic and market risk factors, ensuring a resilient and
              well-rounded investment approach.
            </p>
          </div>
          <div className="servicesDiv">
            <BiPieChartAlt2 color="white" size={50} />
            <h3>Asset Allocation</h3>
            <p>
              With a vigilant eye, we continuously monitor the relative
              performance and valuation of diverse asset classes amid the
              ever-changing global economic landscape. Our approach involves
              making dynamic allocation decisions, informed by perceived
              opportunities and risks across various investment domains.
            </p>
          </div>
          <div className="servicesDiv">
            <PiShieldWarningFill color="white" size={50} />
            <h3>Risk Management</h3>
            <p>
              As a part of our holistic approach, we place a strong emphasis on
              conducting thorough risk assessments and implementing effective
              risk mitigation strategies, which include insurance solutions and
              various other tactics. By diligently evaluating and addressing
              potential financial risks, we aim to provide you with a secure and
              stable financial foundation, safeguarding your wealth and future
              aspirations.
            </p>
          </div>
        </div>
      </div>

      <div className="wealthAndManagementProcessContainer">
        <h1>Our process</h1>
        <div className="wealthAndManagementProcessMain">
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>01.</h2>
              <h3>Getting to Know You</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                Prior to formal engagement, we prioritize understanding your
                personal situation, financial goals, and key concerns that drive
                your search for financial advice. This forms the basis for a
                mutually agreed-upon scope of work. Please find below a
                comprehensive list of our capabilities.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>02.</h2>
              <h3>Gaining Clarity on Your Finances</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                Our comprehensive planning engagements start by jointly
                assessing your balance sheet and cash flow. Access our secure
                client portal to upload documents or link your accounts
                electronically.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>03.</h2>
              <h3>Building Your Financial Vision</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                We collaborate with you to prioritize your goals and assess the
                financial choices and risks involved in achieving them. Through
                close partnership, we establish a baseline projection of your
                future financial wealth, providing a roadmap for your success.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>04.</h2>
              <h3>Analyzing Tradeoffs</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                We use our financial model to develop alternative scenarios,
                informing recommendations on taxes, insurance, and investments
                to align with your goals. Exploring scenarios to align
                recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/get-a-consultation")}
        className="getAConsultationBtnContainer"
      >
        <button>Get a consultation</button>
      </div>

      <div className="dmitContactUsContainer">
        <div>
          <p>
            We are here to address any questions you may have as you implement
            your plan. Additionally, for clients seeking an ongoing partnership,
            we provide comprehensive{" "}
            <span style={{ color: "red" }}>Financial planning</span>.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
      </div>
    </div>
  );
};

export default WealthManagement;
