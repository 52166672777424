import React, { useState, useContext } from "react";
import "../styles/Header.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { AuthContext } from "../AuthContext";

const Header = () => {
  const { userData } = useContext(AuthContext);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showTeamDropdown, setShowTeamDropdown] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const navigate = useNavigate();

  const [openShowcase, setOpenShowcase] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const toggleShowcase = () => {
    setOpenShowcase(!openShowcase);
  };

  const toggleSubmenu = (submenu) => {
    setOpenSubmenu(openSubmenu === submenu ? null : submenu);
  };

  const isMobile = window.innerWidth <= 768;

  // Handle hover for laptop devices
  const handleMouseEnterAbout = () => {
    if (!isMobile) setShowAboutDropdown(true);
  };

  const handleMouseLeaveAbout = () => {
    if (!isMobile) setShowAboutDropdown(false);
  };

  const handleMouseEnterShowcase = () => {
    if (!isMobile) setShowMegaMenu(true);
  };

  const handleMouseLeaveShowcase = () => {
    if (!isMobile) setShowMegaMenu(false);
  };

  // Toggle dropdowns for mobile and tablet
  const toggleHamburgerMenu = () => setShowHamburgerMenu(!showHamburgerMenu);
  const toggleAboutDropdown = () => setShowAboutDropdown(!showAboutDropdown);
  const toggleTeamDropdown = (e) => {
    e.stopPropagation(); // Prevent closing parent dropdown
    setShowTeamDropdown(!showTeamDropdown);
  };

  const naviagteTo = (path) => {
    navigate(path);
    toggleHamburgerMenu();
  };

  return (
    <header className="header">
      <div className="logo" onClick={() => navigate("/")}>
        <img src={logo} alt="Life Signify Logo" />
      </div>

      {/* Hamburger Icon */}
      <div className="hamburger">
        <div
          onClick={() => navigate("/hooponopono-power")}
          className="hooponoponoWebinarBtnMain"
        >
          <p>Webinar</p>
        </div>

        <div onClick={toggleHamburgerMenu} className="hambugerIconContainer">
          {showHamburgerMenu ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>

      <nav className={`nav ${showHamburgerMenu ? "" : "active"}`}>
        <ul className="menu">
          <li onClick={() => naviagteTo("/")}>Home</li>

          <li className="menuItemMobile" onClick={toggleShowcase}>
            <span>
              Showcase
              <ArrowDropDownIcon
                className={`arrow ${openShowcase ? "rotate" : ""}`}
              />
            </span>
            {openShowcase && (
              <ul className="submenu">
                <li
                  className="submenuItem"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSubmenu("personality");
                  }}
                >
                  <span>
                    Personality Development
                    <ArrowDropDownIcon
                      className={`arrow ${
                        openSubmenu === "personality" ? "rotate" : ""
                      }`}
                    />
                  </span>
                  {openSubmenu === "personality" && (
                    <ul className="submenu">
                      <li
                        onClick={() => naviagteTo("/dmit")}
                        className="submenuItem"
                      >
                        DMIT
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/midbrain-activation")}
                      >
                        Midbrain Activation
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/personality-development")}
                      >
                        Personality Development
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/law-of-attraction")}
                      >
                        Law of Attraction
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/dhyanam")}
                      >
                        Dhyanam
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className="submenuItem"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSubmenu("wealth");
                  }}
                >
                  <span>
                    Wealth Management
                    <ArrowDropDownIcon
                      className={`arrow ${
                        openSubmenu === "wealth" ? "rotate" : ""
                      }`}
                    />
                  </span>

                  {openSubmenu === "wealth" && (
                    <ul className="submenu">
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/wealth-management")}
                      >
                        Portfolio Management
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/wealth-management")}
                      >
                        Asset Allocation
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/wealth-management")}
                      >
                        Risk Management
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className="submenuItem"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSubmenu("financial");
                  }}
                >
                  <span>
                    Financial Planning
                    <ArrowDropDownIcon
                      className={`arrow ${
                        openSubmenu === "financial" ? "rotate" : ""
                      }`}
                    />
                  </span>

                  {openSubmenu === "financial" && (
                    <ul className="submenu">
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/financial-planning")}
                      >
                        Investment Planning
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/financial-planning")}
                      >
                        Retirement Planning
                      </li>
                      <li
                        className="submenuItem"
                        onClick={() => naviagteTo("/financial-planning")}
                      >
                        Education Planning
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            )}
          </li>

          <li
            className="menuItem megaMenuContainerMainTop"
            onMouseEnter={() => setShowMegaMenu(true)}
            onMouseLeave={() => setShowMegaMenu(false)}
          >
            <div
              style={{
                marginLeft: 5,
                display: "flex",
                alignItems: "flex-end",
                marginTop: 2,
              }}
            >
              <span>Showcase</span>
              <ArrowDropDownIcon
                className={`arrow ${showMegaMenu ? "rotate" : ""}`}
              />
            </div>
            {showMegaMenu && (
              <div
                className="megaMenu"
                onMouseEnter={() => setShowMegaMenu(true)}
                onMouseLeave={() => setShowMegaMenu(false)}
              >
                <div className="megaMenuContent">
                  <div className="megaMenuContentLeft">
                    <p onClick={() => navigate("/personality-development")}>
                      Personality Development
                    </p>
                    <ul>
                      <li onClick={() => navigate("/dmit")}>DMIT</li>
                      <li onClick={() => navigate("/midbrain-activation")}>
                        Midbrain Activation
                      </li>
                      <li onClick={() => navigate("/personality-development")}>
                        Personality Development
                      </li>
                      <li onClick={() => navigate("/law-of-attraction")}>
                        Law of Attraction
                      </li>
                      <li onClick={() => navigate("/dhyanam")}>Dhyanam</li>
                    </ul>
                  </div>
                  <div className="megaMenuContentRight">
                    <div className="megaMenuContentRightLeft">
                      <p onClick={() => navigate("/wealth-management")}>
                        Wealth Management
                      </p>
                      <ul>
                        <li onClick={() => navigate("/wealth-management")}>
                          Portfolio management
                        </li>
                        <li onClick={() => navigate("/wealth-management")}>
                          Asset allocation
                        </li>
                        <li onClick={() => navigate("/wealth-management")}>
                          Risk management
                        </li>
                      </ul>
                    </div>
                    <div className="megaMenuContentRightLeft">
                      <p onClick={() => navigate("/financial-planning")}>
                        Financial Planning
                      </p>
                      <ul>
                        <li onClick={() => navigate("/financial-planning")}>
                          Investment planning
                        </li>
                        <li onClick={() => navigate("/financial-planning")}>
                          Retirement planning
                        </li>
                        <li onClick={() => navigate("/financial-planning")}>
                          Education planning
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </li>

          {/* About (Dropdown Menu) */}
          <li
            className="menuItem"
            onMouseEnter={handleMouseEnterAbout}
            onMouseLeave={handleMouseLeaveAbout}
            onClick={toggleAboutDropdown}
          >
            <div
              style={{
                marginLeft: 5,
                display: "flex",
                alignItems: "flex-end",
                marginTop: 2,
              }}
            >
              <span>About</span>
              <ArrowDropDownIcon
                className={`arrow ${showAboutDropdown ? "rotate" : ""}`}
              />
            </div>
            {showAboutDropdown && (
              <ul className="dropdown">
                <li onClick={() => naviagteTo("/about")}>About Life Signify</li>
                <li onClick={() => naviagteTo("/dr-ravi-shankar")}>
                  Dr. Ravi Shankar
                </li>
                <li onClick={() => naviagteTo("/archana-shankar-gupta")}>
                  Archana Shankar Gupta
                </li>
                <li className="menuItem" onClick={() => naviagteTo("/team")}>
                  Team
                </li>
              </ul>
            )}
          </li>

          <li onClick={() => naviagteTo("/courses")}>Courses</li>
          <li onClick={() => naviagteTo("/library")}>Library</li>

          <li className="loginMenuMain">
            <Link to="/my-account">
              {userData?.displayName
                ? userData.displayName
                : userData?.email
                ? userData.email.split("@")[0]
                : "Login"}
            </Link>
          </li>

          <li>
            <HiOutlineShoppingBag
              size={24}
              onClick={() => naviagteTo("/cart")}
            />
          </li>

          <li className="hooponoponoLiContainer">
            <Link to="/hooponopono-power">
              <button className="webinarBtn">Webinar</button>
            </Link>
          </li>
          <li className="DhyanamBtnLiContainer">
            <Link to="/dhyanam">
              <button className="DhyanamBtn">Dhyanam</button>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
