import React, { useEffect } from "react";
import "../../styles/DMIT.css";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MidbrainActivations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="DMITContainer">
      <div className="DMITHeader">
        <div className="DMITHeaderTop">
          <div className="rectangle"></div>
          <p>Midbrain Activation</p>
        </div>
        <div className="DMITHeaderBottom">
          <h1>Unlock Your Cognitive Potential with Midbrain Activation</h1>
        </div>
      </div>
      <div className="DMITInfoContainer">
        <p>
          Midbrain Activation is a revolutionary training program designed to
          enhance cognitive functions, improve memory, and boost creativity by
          stimulating the brain's mid-region. This specialized course empowers
          individuals to achieve greater mental clarity and performance.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Benefits of Midbrain Activation</p>
      </div>
      <div className="benefitsOfDMITContainer">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Enhanced Memory
          </h1>
          <p>
            Improve your ability to recall information quickly and accurately.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Increased Creativity
          </h1>
          <p>Unleash your creative potential and think outside the box.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Better Focus
          </h1>
          <p>Achieve heightened concentration and mental clarity.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Balanced Brain Function
          </h1>
          <p>
            Harmonize the left and right brain hemispheres for optimal
            performance.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Stress Reduction
          </h1>
          <p>
            Experience reduced stress levels and improved emotional stability.
          </p>
        </div>
      </div>

      <div className="howDmitWorksContainer">
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>How Midbrain Activation Works</p>
          </div>
          <p>
            Midbrain Activation involves a series of exercises and techniques
            designed to stimulate the midbrain, which acts as a bridge between
            the left and right hemispheres. This process includes:
          </p>
          <ol>
            <li>
              <b>Brain Exercises:</b> Engaging activities that activate midbrain
              functions.
            </li>
            <li>
              <b>Meditative Practices:</b> Techniques to calm the mind and
              enhance focus.
            </li>
            <li>
              <b>Sensory Stimulation:</b> Activities that stimulate the senses
              and promote neural connections.
            </li>
            <li>
              <b>Ongoing Practice:</b> Continuous training to maintain and
              improve cognitive abilities.
            </li>
          </ol>
        </div>
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>Who Can Benefit?</p>
          </div>
          <ul>
            <li>
              <b>Students:</b> Improve learning abilities and academic
              performance.
            </li>
            <li>
              <b>Professionals:</b> Enhance problem-solving skills and
              productivity.
            </li>
            <li>
              <b>Creatives:</b> Boost creative thinking and innovation.
            </li>
            <li>
              <b>Individuals:</b> Anyone seeking to improve mental performance
              and overall brain health.
            </li>
          </ul>
        </div>
      </div>
      <div className="dmitContactUsContainer">
        <h1>Get Started with Midbrain Activation</h1>
        <div>
          <p>
            Unlock your brain's full potential with Midbrain Activation
            training. Contact us today to schedule your session and
            consultation.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Contact Us Now
        </button>
      </div>
    </div>
  );
};

export default MidbrainActivations;
