import React, { useEffect } from "react";
import "../styles/DrRaviShankar.css";
import Back_Image from "../assets/before_footer_Image.png";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ravi from "../assets/family.jpg";
const DrRaviShankar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="DrRaviShankarMain">
      <div className="first-sec">
        <div className="first-sec-first">
          <div className="skew-rec"></div>
          <h2>Dr. Ravi Shankar</h2>
        </div>
        <div className="first-sec-sec">
          <h1 className="f-h2">
            Inspiring Excellence: The World of Dr. Ravi Shankar
          </h1>
        </div>
      </div>
      <div className="second-sec">
        <div className="sec-first">
          <div className="sec-first-first">
            <h2>
              Meet Dr. Ravi Shankar, a distinguished former civil servant turned
              motivational speaker and life coach. With an illustrious career
              spanning various administrative roles, Dr. Shankar has
              transitioned into a guiding light for those seeking personal and
              professional transformation. His journey from the corridors of
              power to the heart of personal development exemplifies a life
              dedicated to service, learning, and empowerment. Dr. Shankar's
              unique blend of experience and empathy forms the cornerstone of
              his mission to inspire and nurture the potential within each
              individual
            </h2>
          </div>
          <div className="sec-first-second">
            <img src={ravi} alt="dr ravi shankar" />
          </div>
        </div>
        <div className="sec-sec">
          <h1 className="sec-sec-h1">Professional Journey</h1>
          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">Gomti Gramin Bank</h2>
              <h2 className="sec-sec-first-h2-f2">
                Probationary Officer & Bank Manager
              </h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Excelled in financial oversight, leading to enhanced operational
                efficiency and customer satisfaction through innovative banking
                solutions.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">Various Districts</h2>
              <h2 className="sec-sec-first-h2-f2">Sub-Divisional Magistrate</h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Orchestrated significant administrative reforms, fostering
                community development and resolving numerous high-tension
                conflicts with strategic diplomacy.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">-</h2>
              <h2 className="sec-sec-first-h2-f2">
                City Magistrate & Additional District Magistrate
              </h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Championed public safety initiatives and regulatory compliance,
                spearheading community welfare programs that significantly
                improved local governance and citizen well-being.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">
                UP Skill Development Mission
              </h2>
              <h2 className="sec-sec-first-h2-f2">
                Additional Mission Director
              </h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Pioneered skill development and employment strategies,
                significantly boosting local employment rates through targeted
                training programs and partnerships with industries.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">PARAG</h2>
              <h2 className="sec-sec-first-h2-f2">Chief General Manager</h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Transformed operational efficiencies and spearheaded staff
                development initiatives, leading to record-breaking performance
                improvements and enhanced product quality in the dairy sector.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">-</h2>
              <h2 className="sec-sec-first-h2-f2">
                City Commissioner & Special Secretary
              </h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p">
                Masterminded financial management and strategic urban planning,
                leading to sustainable city development and enhanced public
                services.
              </p>
            </div>
          </div>

          <div className="third-section">
            <div className="third-sec-first"></div>
            <div className="third-sec-sec">
              <div className="third-sec-sec-first">
                <div>
                  <div className="skew-rec"></div>
                  <h2 className="third-sec-sec-first-h2">Philosophy</h2>
                </div>
                <h1 className="third-sec-sec-first-h1">
                  Philosophy & Approach
                </h1>
                <p className="third-sec-sec-first-h3">
                  At the heart of Dr. Ravi Shankar's ethos lies a profound
                  belief in the boundless potential within each of us. Drawing
                  from a rich tapestry of life experiences, Dr. Shankar has
                  crafted a coaching philosophy that transcends conventional
                  wisdom, focusing on unlocking this latent potential.
                </p>
              </div>
              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">
                  Holistic Empowerment
                </h2>
                <p className="third-sec-sec-second-p">
                  Dr. Shankar's method is all-encompassing, nurturing the mind,
                  body, and spirit. He intertwines practical life skills with
                  emotional and spiritual insights, fostering a well-rounded
                  approach to personal growth.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">
                  Sustainable Transformation
                </h2>
                <p className="third-sec-sec-second-p">
                  His sessions go beyond temporary motivation, aiming for
                  deep-rooted change. Dr. Shankar equips individuals with the
                  tools to steer their lives confidently, make decisions wisely,
                  and face life's hurdles with unwavering resilience.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <h2 className="third-sec-sec-second-h2">Community Building</h2>
                <p className="third-sec-sec-second-p">
                  Dr. Shankar envisions a society where individuals uplift one
                  another, creating an environment of mutual growth and support.
                  His approach is not just about individual success but about
                  cultivating a collective well-being.
                </p>
              </div>

              <div className="third-sec-sec-first1">
                <div>
                  <div className="skew-rec"></div>
                  <h2 className="third-sec-sec-first-h2">Achievements</h2>
                </div>
                <h1 className="third-sec-sec-first-h1">
                  Achievements and Impact
                </h1>
                <p className="third-sec-sec-first-h3">
                  At the heart of Dr. Ravi Shankar's ethos lies a profound
                  belief in the boundless potential within each of us. Drawing
                  from a rich tapestry of life experiences, Dr. Shankar has
                  crafted a coaching philosophy that transcends conventional
                  wisdom, focusing on unlocking this latent potential.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <p className="third-sec-sec-second-p">
                  As the{" "}
                  <span style={{ color: "red" }}>
                    Additional Mission Director at UP Skill Development Mission
                  </span>
                  , he led innovative experiments in skill development,
                  training, and placement that were praised by the Uttar Pradesh
                  Government. His initiatives in online monitoring of training
                  classes and competency assessment of trainers significantly
                  enhanced the quality and effectiveness of skill development
                  programs.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <p className="third-sec-sec-second-p">
                  In his role as{" "}
                  <span style={{ color: "red" }}>City Commissioner</span>, he
                  met and exceeded budget targets through responsible planning
                  and resource allocation, improving departmental revenue and
                  efficiency. His leadership ensured that work by contracted
                  employees was delivered on schedule, fostering a culture of
                  accountability and excellence.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <p className="third-sec-sec-second-p">
                  In his capacity as{" "}
                  <span style={{ color: "red" }}>
                    Chief General Manager at PARAG
                  </span>
                  , Dr. Shankar supported senior management in daily operations
                  and strategic objectives, leading to improved staff
                  performance and operational efficiencies. His leadership in
                  the Namami Gange Gramin JalaPutri Vibhaag initiative showcased
                  his commitment to community welfare and environmental
                  conservation.
                </p>
              </div>

              <div className="third-sec-sec-second">
                <p className="third-sec-sec-second-p">
                  These achievements underline Dr. Shankar's profound impact in
                  various administrative roles, showcasing his leadership,
                  innovation, and dedication to public service and community
                  development.
                </p>
              </div>
            </div>
          </div>

          <div className="back_image">
            <img src={Back_Image} />
          </div>

          <div className="content-last-sec">
            <h1 className="content-h1">Personal Insights</h1>
            <div className="content-last">
              <div className="content-section">
                <CheckSharpIcon />
                <p>
                  Dr. Ravi Shankar's life extends beyond his professional
                  achievements, encapsulating a rich tapestry of interests and
                  passions that reflect his multifaceted personality. A scholar
                  of Hindi and Sanskrit literature, his academic pursuits have
                  deeply influenced his philosophical outlook and coaching
                  style, blending ancient wisdom with contemporary practices. As
                  an athlete and pistol shooter, Dr. Shankar embodies discipline
                  and precision, traits he encourages in his mentees.
                </p>
              </div>
              <div className="content-section">
                <CheckSharpIcon />
                <p>
                  His commitment to a Vedic lifestyle, coupled with interests in
                  yoga, meditation, nature conservation, and organic farming,
                  speaks to his holistic approach to life. These personal
                  pursuits underscore his belief in the interconnectedness of
                  mind, body, and environment, shaping his vision for a balanced
                  and sustainable way of living. Dr. Shankar's life is a
                  testament to the principle that personal well-being and
                  professional excellence are not mutually exclusive but rather
                  complementary facets of a fulfilled life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrRaviShankar;
