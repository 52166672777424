import React, { useEffect } from "react";
import "../../styles/DMIT.css";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DMIT = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="DMITContainer">
      <div className="DMITHeader">
        <div className="DMITHeaderTop">
          <div className="rectangle"></div>
          <p>DMIT</p>
        </div>
        <div className="DMITHeaderBottom">
          <h1>Dermatoglyphics Multiple Intelligence Test (DMIT)</h1>
        </div>
      </div>
      <div className="DMITInfoContainer">
        <p>
          The Dermatoglyphics Multiple Intelligence Test (DMIT) is a scientific
          method that analyzes fingerprint patterns to reveal your unique
          intellectual strengths and learning styles. This insightful tool helps
          individuals understand their innate potential and guides them toward
          optimal personal and professional development.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Benefits of DMIT</p>
      </div>
      <div className="benefitsOfDMITContainer">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Personalized Insights
          </h1>
          <p>
            Gain a deep understanding of your natural abilities and talents.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Educational Guidance
          </h1>
          <p>Tailor your learning strategies to maximize academic success.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Career Planning
          </h1>
          <p>Identify career paths that align with your inherent strengths.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Parenting Support
          </h1>
          <p>
            Equip parents with knowledge to nurture their child's potential
            effectively.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Holistic Development
          </h1>
          <p>
            Foster overall growth by aligning activities with natural
            inclinations.
          </p>
        </div>
      </div>

      <div className="howDmitWorksContainer">
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>How DMIT Works?</p>
          </div>
          <p>
            DMIT utilizes dermatoglyphics, the study of fingerprint patterns,
            which are unique to every individual. By analyzing these patterns,
            experts can determine various cognitive and learning abilities. This
            process involves:
          </p>
          <ol>
            <li>
              <b>Fingerprint Collection:</b> Simple and non-invasive fingerprint
              scanning.
            </li>
            <li>
              <b>Pattern Analysis:</b> Detailed examination of ridge patterns
              and formations.
            </li>
            <li>
              <b>Report Generation:</b> Comprehensive report highlighting
              multiple intelligences.
            </li>
            <li>
              <b>Consultation:</b> Expert guidance to interpret the results and
              plan actionable steps.
            </li>
          </ol>
        </div>
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>Who Can Benefit?</p>
          </div>
          <ul>
            <li>
              <b>Students:</b> Optimize learning strategies and career choices.
            </li>
            <li>
              <b>Professionals:</b> Enhance career development and job
              satisfaction.
            </li>
            <li>
              <b>Parents:</b> Support children's educational and personal
              growth.
            </li>
            <li>
              <b>Educators:</b> Tailor teaching methods to suit diverse learning
              styles.
            </li>
            <li>
              <b>Individuals:</b> Anyone looking to understand their true
              potential.
            </li>
          </ul>
        </div>
      </div>
      <div className="dmitContactUsContainer">
        <h1>Get Started with DMIT</h1>
        <div>
          <p>
            Embark on a journey of self-discovery and unlock your potential with
            DMIT. Contact us today to schedule your assessment and consultation.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Contact Us Now
        </button>
      </div>
    </div>
  );
};

export default DMIT;
