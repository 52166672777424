import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/PaymentStatus.css";
import success from "../assets/success.gif";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="paymentStatusContainer">
      <img src={success} alt="successful" />
      <h1>Payment Successful!</h1>
      <p>
        Thank you for your purchase. Your payment was processed successfully.
      </p>
      <p>Redirecting...</p>
    </div>
  );
};

export default PaymentSuccess;
