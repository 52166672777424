import React, { useEffect } from "react";
import "../../styles/WealthAndFinancial.css";
import { FaCheck } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";
import { LuRockingChair } from "react-icons/lu";
import { FaBrain } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const FinancialPlanning = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="wealthAndManagementContainer">
      <div className="wealthAndManagementHeader">
        <div className="wealthAndManagementHeaderTop">
          <div className="rectangle"></div>
          <p>Financial Planning</p>
        </div>
        <div className="wealthAndManagementHeaderBottom">
          <h1>Securing Your Future: Tailored Financial Planning Services</h1>
        </div>
      </div>
      <div className="wealthAndManagementInfoContainer">
        <p>
          Welcome to our Financial Planning Services, where your financial
          well-being and future security are our top priorities. We specialize
          in creating personalized strategies that align with your individual
          goals, from growing your wealth to planning for retirement and funding
          education. Let us guide you toward making informed financial decisions
          for a brighter, more secure future.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Our Mission</p>
      </div>
      <div className="ourMissionWealthAndManagement">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Confidence
          </h1>
          <p>
            We educate and empower you on investing, taxes, and insurance,
            instilling confidence in your financial journey.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Clarity
          </h1>
          <p>
            We simplify complex financial concepts into plain, straightforward
            language, eliminating the need for deciphering jargon.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Empowerment
          </h1>
          <p>
            We empower you with actionable recommendations, giving you complete
            control over your financial situation and future.
          </p>
        </div>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Our Capabilities</p>
      </div>
      <div className="managementSolutionContainer">
        <div className="managementSolutionHeading">
          <h1>Empowering Financial Success</h1>
        </div>
        <div className="serviceOptions">
          <div className="servicesDiv">
            <LuClipboardList color="white" size={50} />
            <h3>Investment Planning</h3>
            <p>
              We analyze your current investment approach and engage in
              insightful discussions to provide recommendations for your next
              steps. For clients seeking ongoing investment services, we
              encourage you to explore our comprehensive Wealth Management
              service. By leveraging our expertise, we aim to optimize your
              investment strategy and assist you in achieving your financial
              objectives.
            </p>
          </div>
          <div className="servicesDiv">
            <LuRockingChair color="white" size={50} />
            <h3>Retirement Planning</h3>
            <p>
              Whether retirement is a distant goal or just around the corner, we
              are here to assist you in reviewing your current retirement plan
              and making necessary decisions to ensure its success. At every
              step of the way, our goal is to provide you with the information
              and support needed to make confident and well-informed choices.
            </p>
          </div>
          <div className="servicesDiv">
            <FaBrain color="white" size={50} />
            <h3>Education Planning</h3>
            <p>
              We understand the importance of balancing education planning with
              your overall financial goals, and we're here to assist you every
              step of the way. By working together, we can ensure that education
              planning becomes an integral part of your comprehensive financial
              plan, helping you make informed decisions, educational future
              while maintaining your overall financial well-being.
            </p>
          </div>
        </div>
      </div>

      <div className="wealthAndManagementProcessContainer">
        <h1>Our process</h1>
        <div className="wealthAndManagementProcessMain">
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>01.</h2>
              <h3>Getting to Know You</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                Prior to formal engagement, we prioritize understanding your
                personal situation, financial goals, and key concerns that drive
                your search for financial advice. This forms the basis for a
                mutually agreed-upon scope of work. Please find below a
                comprehensive list of our capabilities.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>02.</h2>
              <h3>Gaining Clarity on Your Finances</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                Our comprehensive planning engagements start by jointly
                assessing your balance sheet and cash flow. Access our secure
                client portal to upload documents or link your accounts
                electronically.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>03.</h2>
              <h3>Building Your Financial Vision</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                We collaborate with you to prioritize your goals and assess the
                financial choices and risks involved in achieving them. Through
                close partnership, we establish a baseline projection of your
                future financial wealth, providing a roadmap for your success.
              </p>
            </div>
          </div>
          <div className="wealthAndManagementProcessInfo">
            <div className="wealthAndManagementProcessInfoLeft">
              <h2>04.</h2>
              <h3>Analyzing Tradeoffs</h3>
            </div>
            <div className="wealthAndManagementProcessInfoRight">
              <p>
                We use our financial model to develop alternative scenarios,
                informing recommendations on taxes, insurance, and investments
                to align with your goals. Exploring scenarios to align
                recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="getAConsultationBtnContainer">
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Get a consultation
        </button>
      </div>

      <div className="dmitContactUsContainer">
        <div>
          <p>
            We are here to address any questions you may have as you implement
            your plan. Additionally, for clients seeking an ongoing partnership,
            we provide comprehensive
            <span style={{ color: "red" }}> Wealth Management Services</span>.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialPlanning;
