import React, { useEffect } from "react";

const PankajTripathi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="DrRaviShankarMain">
      <div className="first-sec">
        <div className="first-sec-first">
          <div className="skew-rec"></div>
          <h2 className="first-h">Pankaj Tripathi</h2>
        </div>
        <div className="first-sec-sec">
          <h1 className="f-h2">Project Management and Development Expert</h1>
        </div>
      </div>

      <div className="second-sec">
        <div className="sec-first">
          <div className="sec-first-first">
            <h2>
              Pankaj Tripathi, with over 10 years of professional experience,
              has excelled in various roles from GM Projects at Expert Serv
              Group of Companies to academic positions in electronics and
              communications. He has led numerous high-value projects, including
              CBG production units and educational institutions, and consulted
              on major initiatives like the “Pradhanmantri Matsya Sampada
              Yojana” and cargo business planning for Spice Jet. Pankaj’s
              diverse expertise and dedication to innovation and quality
              improvement make him an invaluable asset to any organization.
            </h2>
          </div>
          <div className="sec-first-second">
            <img
              src="https://lifesignify.com/wp-content/uploads/2024/06/Pankaj-trtipathi.png"
              width={100}
              height={400}
            />
          </div>
        </div>

        <div className="sec-sec">
          <h1 className="sec-sec-h1">Professional Journey</h1>
          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">
                Expert Serv Group of Companies
              </h2>
              <h2 className="sec-sec-first-h2-f2">GM Projects</h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p1">
                Developed sustainable business models, executed high-value
                projects, and improved student quality of life.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">
                Various Academic Institutes
              </h2>
              <h2 className="sec-sec-first-h2-f2">
                Assistant Professor, Electronics and Communications
              </h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p1">
                Taught in multiple prestigious institutes, enhancing academic
                standards and contributing to student success.
              </p>
            </div>
          </div>

          <div className="sec-sec-first">
            <div className="s-s-f1">
              <h2 className="sec-sec-first-h2-f">Consultant</h2>
              <h2 className="sec-sec-first-h2-f2">Project Consultant</h2>
            </div>
            <div className="s-s-f2">
              <p className="sec-sec-first-p1">
                Managed high-impact projects, including CBG production units and
                business planning for major companies.
              </p>
            </div>
          </div>
        </div>

        <div className="third-section">
          <div className="third-sec-first"></div>
          <div className="third-sec-sec">
            <div className="third-sec-sec-first">
              <div>
                <div className="skew-rec"></div>
                <h2 className="third-sec-sec-first-h2">Achievements</h2>
              </div>
            </div>
            <div className="third-sec-sec-second">
              <h2 className="third-sec-sec-second-h2">Innovative Cure</h2>
              <p className="third-sec-sec-second-p">
                Led a leading health sector company, driving growth and
                innovation for 15 years.
              </p>
            </div>

            <div className="third-sec-sec-second">
              <h2 className="third-sec-sec-second-h2">Academics</h2>
              <p className="third-sec-sec-second-p">
                Held teaching positions in reputed institutes, contributing to
                the field of electronics and communications.
              </p>
            </div>

            <div className="third-sec-sec-second">
              <h2 className="third-sec-sec-second-h2">Project Management</h2>
              <p className="third-sec-sec-second-p">
                Successfully planned and executed projects worth millions in
                various sectors, including education and renewable energy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PankajTripathi;
