import React, { useEffect, useContext } from "react";
import "../styles/Dashboard.css";
import { AuthContext } from "../AuthContext";

const Dashboard = () => {
  const { userData, logout } = useContext(AuthContext);
  const username = userData?.email?.split("@")[0];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="dashboardContainer">
      <p>
        Hello {username} (not {username}?{" "}
        <span onClick={() => logout()} style={{ cursor: "pointer" }}>
          Log out
        </span>
        )
      </p>
      <p>
        From your account dashboard you can view your{" "}
        <span style={{ cursor: "pointer" }}>recent orders</span>, manage your{" "}
        <span style={{ cursor: "pointer" }}>
          shipping and billing addresses
        </span>
        , and{" "}
        <span style={{ cursor: "pointer" }}>
          edit your password and account details
        </span>
        .
      </p>
    </div>
  );
};

export default Dashboard;
