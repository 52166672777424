import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../styles/DhyanamShop.css";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";

const DhyanamShop = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const { isLoggedIn } = useContext(AuthContext);
  const [loadingId, setLoadingId] = useState(null);

  const fetchProducts = async () => {
    try {
      const response = await axios.get("https://api.lifesignify.com/api/dhyanam");
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const addToCart = async (Id) => {
    if (!isLoggedIn) {
      alert("Please log in to add items to your cart.");
      return;
    }
    setLoadingId(Id);

    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    try {
      const itemType = "Dhyanam";
      const response = await fetch("https://api.lifesignify.com/api/cart/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          productId: Id,
          quantity: 1,
          userId,
          itemType: itemType,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add book to cart");
      }

      const cartData = await response.json();
      alert("Product added to cart successfully!");
      navigate("/cart");
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setLoadingId(null);
    }
  };

  return (
    <div className="dhyanamShopContainer">
      <h1>Dhyanam Shop</h1>
      <div className="dhyanamShopMain">
        {items.map((item) => (
          <div key={item._id} className="dhyanamShopCard">
            <div className="dhyanamCardTop">
              <img src={item.image} alt={item.name} />
            </div>
            <div className="dhyanamCardBottom">
              <h3>{item.name}</h3>
              <p>₹{item.price}</p>
              <button
                onClick={() => {
                  addToCart(item._id);
                }}
                disabled={loadingId === item._id}
              >
                {loadingId === item._id ? "Adding to Cart..." : "Add To Cart"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DhyanamShop;
