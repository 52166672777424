import React, { useEffect, useState, useRef } from "react";
import "../styles/HooponoponoPowerWebinar.css";
import axios from "axios";
import check from "../assets/check.png";

import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { MdTimer } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { FaUserGraduate } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import emailjs from "emailjs-com";

const HooponoponoPowerWebinar = () => {
  const webinarFormRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [webinars, setWebinars] = useState([]);

  const fetchWebinars = async () => {
    try {
      const response = await axios.get("https://api.lifesignify.com/api/webinarad");
      setWebinars(response.data.webinars);
    } catch (error) {
      console.error("Error fetching webinars", error);
    }
  };

  useEffect(() => {
    fetchWebinars();
  }, []);

  const studentItems = [
    "Accept Yourself",
    "Stress and Anger Management",
    "Get Rid of Fear",
    "Being Social",
    "Make Learning Your Habit",
    "Communication Skills & Public Speaking",
    "Deal with Adolescence",
    "Responsibilities and You",
    "School Days and Relationships",
    "Planning Your Future",
    "Career Counselling",
  ];

  const professionalItems = [
    "Vision and perspectives",
    "Exploring winner within you",
    "Handling Personal and professional life",
    "Stress free life",
  ];

  const corporateItems = [
    "Peak Potential Development",
    "Success is a Mindset",
    "Personality and Behavior Matters",
    "Communication Skills",
    "Self-Care",
    "Smart Working",
    "Self-Development",
    "Health: Physical, Mental, and Emotional Wealth",
    "Positive Mindset",
    "Body Language and Communication Skills",
    "Path of Happiness",
    "Spiritual Quotient and Peace",
    "Millionaire Mindset Development",
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    healthChallenge: false,
    mentalChallenge: false,
    financialChallenge: false,
    otherChallenge: false,
    other: "",
    gender: "",
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      gender: formData.gender || "Not specified",
    };

    try {
      const response = await fetch("https://api.lifesignify.com/api/webinars", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const emailResponse = await emailjs.send(
          "service_rny5mbw",
          "template_yu8o2m9",
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            city: formData.city,
            state: formData.state,
            healthChallenge: formData.healthChallenge ? "Yes" : "No",
            mentalChallenge: formData.mentalChallenge ? "Yes" : "No",
            financialChallenge: formData.financialChallenge ? "Yes" : "No",
            other: formData.otherChallenge ? formData.other : null,
            gender: formData.gender,
          },
          "HO3SlQI_fcJJn2AbH"
        );

        if (emailResponse.status === 200) {
          alert("Your information has been submitted successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            healthChallenge: false,
            mentalChallenge: false,
            financialChallenge: false,
            otherChallenge: false,
            other: "",
            gender: "",
          });
        } else {
          throw new Error("Failed to send email");
        }
      } else {
        alert("There was a problem submitting your information.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const convertTo12HourFormat = (time) => {
    if (!time) {
      return "";
    }
    let [hour, minute] = time.split(":");
    hour = parseInt(hour, 10);
    let period = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${hour}:${minute} ${period}`;
  };

  return (
    <div className="hooponoponoPowerWebinarContainer">
      <div className="hooponoponoImgContainer">
        <img
          src={webinars[0]?.poster}
          alt={webinars[0]?.title}
          className="largeImage"
        />
        <img
          src={webinars[0]?.poster}
          alt={webinars[0]?.title}
          className="smallScreen"
        />
      </div>
      <div className="webinarInfo">
        <div className="webinarInfoDiv">
          <div className="webinarInfoIconDiv">
            <FaRegCalendarAlt color="white" size={35} />
          </div>
          <div className="webinarInfoDivDescription">
            <p className="webinarInfoDivHeading">Date</p>
            <p className="webinarInfoDivData">
              {new Date(webinars[0]?.date)
                .toLocaleDateString("en-GB")
                .split("/")
                .join("-")}
            </p>
          </div>
        </div>
        <div className="webinarInfoDiv">
          <div className="webinarInfoIconDiv">
            <IoMdTime color="white" size={35} />
          </div>
          <div className="webinarInfoDivDescription">
            <p className="webinarInfoDivHeading">Time</p>
            <p className="webinarInfoDivData">
              {convertTo12HourFormat(webinars[0]?.time)}
            </p>
          </div>
        </div>
        <div className="webinarInfoDiv">
          <div className="webinarInfoIconDiv">
            <MdTimer color="white" size={35} />
          </div>
          <div className="webinarInfoDivDescription">
            <p className="webinarInfoDivHeading">Duration</p>
            <p className="webinarInfoDivData">{webinars[0]?.duration} hours</p>
          </div>
        </div>
        <div className="webinarInfoDiv">
          <button
            onClick={() => {
              if (webinarFormRef.current) {
                webinarFormRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <CgNotes style={{ marginRight: 10 }} /> Enroll Now
          </button>
        </div>
      </div>
      <div className="webinarInformation">
        <h1>{webinars[0]?.title}</h1>
        <p>{webinars[0]?.titleDesc}</p>
      </div>

      <div className="whyYouCantMissWebinarContainer">
        <div className="whyYouCantMissWebinarHeading">
          <div className="rectangle"></div>
          <h1>Why You Can't Miss This Webinar?</h1>
        </div>
        <div className="whyYouCantMissWebinarReasonMain">
          {webinars[0]?.reasons?.map((reason, index) => (
            <div className="whyYouCantMissWebinarReason" key={index}>
              <div className="whyYouCantMissWebinarReasonIcon">
                <img src={check} alt="check" />
              </div>
              <div className="whyYouCantMissWebinarReasonInfo">
                <h1>{reason.reason}</h1>
                <p style={{ color: "black" }}>{reason.subReason}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="whatYouGetFromWebinar">
        <div className="whatYouGetFromWebinarHeading">
          <div className="rectangle"></div>
          <h1>Unlock Your Potential with Tailored Training Programs</h1>
        </div>
        <div className="whatYouGetFromWebinarContent">
          <div className="servicesDiv">
            <FaUserGraduate color="white" size={40} />
            <h3>For Students</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {studentItems.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    fontSize: 16,
                    fontWeight: "400",
                  }}
                >
                  <FaCheck
                    size={16}
                    color="white"
                    style={{ marginRight: 10, fontWeight: "bold" }}
                  />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="servicesDiv">
            <FaUserTie color="white" size={40} />
            <h3>Professionals</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {professionalItems.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    fontSize: 16,
                    fontWeight: "400",
                  }}
                >
                  <FaCheck
                    size={16}
                    color="white"
                    style={{ marginRight: 10, fontWeight: "bold" }}
                  />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className="servicesDiv">
            <FaUsers color="white" size={40} />
            <h3>Corporate</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {corporateItems.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "14px",
                    fontSize: 16,
                    fontWeight: "400",
                  }}
                >
                  <FaCheck
                    size={16}
                    color="white"
                    style={{ marginRight: 10, fontWeight: "bold" }}
                  />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="reserveSeatFormContainer" ref={webinarFormRef}>
        <div className="reserveSeatFormHeading">
          <div className="rectangle"></div>
          <h1>
            Don't miss out on this life-changing opportunity! Reserve your spot
            now.
          </h1>
        </div>
        <p>Please fill out the form below to Enroll</p>
        <form onSubmit={handleSubmit} className="reserveSeatFormMain">
          <h3>What is your basic problem?</h3>

          <div className="checkboxGroup">
            <label>
              <input
                type="checkbox"
                name="healthChallenge"
                checked={formData.healthChallenge}
                onChange={handleChange}
              />{" "}
              Health Challenge
            </label>
            <label>
              <input
                type="checkbox"
                name="mentalChallenge"
                checked={formData.mentalChallenge}
                onChange={handleChange}
              />{" "}
              Mental - Stress, Depression, Fear, Phobia
            </label>
            <label>
              <input
                type="checkbox"
                name="financialChallenge"
                checked={formData.financialChallenge}
                onChange={handleChange}
              />{" "}
              Financial Challenge
            </label>
          </div>

          <label className="otherCheckbox">
            <input
              type="checkbox"
              name="otherChallenge"
              checked={formData.otherChallenge}
              onChange={handleChange}
            />
            Other
          </label>

          <input
            type="text"
            name="other"
            placeholder="Other"
            value={formData.other}
            onChange={handleChange}
            style={{ width: "175px", marginBottom: "50px" }}
          />

          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
          />

          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Your Phone"
            value={formData.phone}
            onChange={handleChange}
          />

          <label>Gender</label>
          <div className="genderGroup">
            <label>
              <input
                type="radio"
                name="gender"
                value="male"
                checked={formData.gender === "male"}
                onChange={handleChange}
              />{" "}
              Male
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="female"
                checked={formData.gender === "female"}
                onChange={handleChange}
              />{" "}
              Female
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="preferNotToSay"
                checked={formData.gender === "preferNotToSay"}
                onChange={handleChange}
              />
              Prefer not to say
            </label>
          </div>

          <h3 style={{ marginTop: 30 }}>Address</h3>
          <div className="addressGroup">
            <div>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
              />
              <label htmlFor="city">City</label>
            </div>
            <div>
              <input
                type="text"
                id="state"
                name="state"
                placeholder="State / Province / Region"
                value={formData.state}
                onChange={handleChange}
              />
              <label htmlFor="state">State / Province / Region</label>
            </div>
          </div>
          <div className="webinarReserveBtnContainer">
            <button type="submit" className="webinarReserveBtn">
              Pay Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HooponoponoPowerWebinar;
