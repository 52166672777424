import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../styles/TeamDetails.css";

const TeamDetails = () => {
  const { id } = useParams();
  const [teamMember, setTeamMember] = useState(null);

  useEffect(() => {
    const fetchTeamMember = async () => {
      try {
        const response = await fetch(`https://api.lifesignify.com/api/teams/${id}`);
        if (!response.ok) {
          throw new Error("Team member not found");
        }
        const data = await response.json();
        setTeamMember(data);
      } catch (error) {
        throw new Error(error.message);
      }
    };

    fetchTeamMember();
  }, []);

  return (
    <div className="teamDetailsContainer">
      <div className="teamDetails">
        <div className="teamImage">
          <img src={teamMember?.image} alt={teamMember?.name} />
        </div>
        <div className="teamInfo">
          <h2>{teamMember?.name}</h2>
          <h3>{teamMember?.designation}</h3>
          <p>
            Meet Dr. Ravi Shankar, a distinguished former civil servant turned
            motivational speaker and life coach. With an illustrious career
            spanning various administrative roles, Dr. Shankar has transitioned
            into a guiding light for those seeking personal and professional
            transformation. His journey from the corridors of power to the heart
            of personal development exemplifies a life dedicated to service,
            learning, and empowerment. Dr. Shankar's unique blend of experience
            and empathy forms the cornerstone of his mission to inspire and
            nurture the potential within each individual.
          </p>
        </div>
      </div>
      <h2>Professional Journey</h2>
      <div className="teamExperienceMain">
        <div className="teamExperienceDiv">
          <h3>Gomti Gramin Bank</h3>
          <h4>Probationary Officer & Bank Manager</h4>
          <p>
            Excelled in financial oversight, leading to enhanced operational
            efficiency and customer satisfaction through innovative banking
            solutions.
          </p>
        </div>
        <div className="teamExperienceDiv">
          <h3>Various Districts</h3>
          <h4>Sub-Divisional Magistrate</h4>
          <p>
            Orchestrated significant administrative reforms, fostering community
            development and resolving numerous high-tension conflicts with
            strategic diplomacy.
          </p>
        </div>
        <div className="teamExperienceDiv">
          <h3>-</h3>
          <h4>City Magistrate & Additional District Magistrate</h4>
          <p>
            Championed public safety initiatives and regulatory compliance,
            spearheading community welfare programs that significantly improved
            local governance and citizen well-being.
          </p>
        </div>
        <div className="teamExperienceDiv">
          <h3>UP Skill Development Mission</h3>
          <h4>Additional Mission Director</h4>
          <p>
            Pioneered skill development and employment strategies, significantly
            boosting local employment rates through targeted training programs
            and partnerships with industries.
          </p>
        </div>
        <div className="teamExperienceDiv">
          <h3>PARAG</h3>
          <h4>Chief General Manager</h4>
          <p>
            Transformed operational efficiencies and spearheaded staff
            development initiatives, leading to record-breaking performance
            improvements and enhanced product quality in the dairy sector.
          </p>
        </div>
        <div className="teamExperienceDiv">
          <h3>-</h3>
          <h4>City Commissioner & Special Secretary</h4>
          <p>
            Masterminded financial management and strategic urban planning,
            leading to sustainable city development and enhanced public
            services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
