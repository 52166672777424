import React, { useEffect } from "react";
import "../../styles/DMIT.css";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PersonalityDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="DMITContainer">
      <div className="DMITHeader">
        <div className="DMITHeaderTop">
          <div className="rectangle"></div>
          <p>Personality Development</p>
        </div>
        <div className="DMITHeaderBottom">
          <h1>Transform Yourself with Personality Development Training</h1>
        </div>
      </div>
      <div className="DMITInfoContainer">
        <p>
          Personality Development training focuses on enhancing personal and
          professional life by building confidence, improving communication
          skills, and developing emotional intelligence. This comprehensive
          program is designed to help individuals become the best version of
          themselves.
        </p>
      </div>

      <div className="ourPurposeHeading">
        <div className="rectangle"></div>
        <p>Benefits of Personality Development:</p>
      </div>
      <div className="benefitsOfDMITContainer">
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Increased Confidence
          </h1>
          <p>IBoost self-esteem and assertiveness.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Improved Communication
          </h1>
          <p>Enhance verbal and non-verbal communication skills.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Emotional Intelligence
          </h1>
          <p>
            Develop the ability to understand and manage emotions effectively.
          </p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Professional Growth
          </h1>
          <p>Gain skills that contribute to career advancement and success.</p>
        </div>
        <div>
          <h1>
            <FaCheck
              style={{ color: "white", fontSize: "22px", marginRight: 10 }}
            />
            Personal Fulfillment
          </h1>
          <p>Achieve a balanced and fulfilling personal life.</p>
        </div>
      </div>

      <div className="howDmitWorksContainer">
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>How Personality Development Works</p>
          </div>
          <p>
            Personality Development involves a series of training sessions and
            activities tailored to individual needs. This process includes:
          </p>
          <ol>
            <li>
              <b>Self-Assessment:</b> Identifying strengths and areas for
              improvement.
            </li>
            <li>
              <b>Skill-Building Exercises:</b> Activities to enhance specific
              personal and professional skills.
            </li>
            <li>
              <b>Behavioral Training:</b> Techniques to improve interpersonal
              interactions and emotional responses.
            </li>
            <li>
              <b>Continuous Improvement:</b> Ongoing support and development to
              maintain growth.
            </li>
          </ol>
        </div>
        <div>
          <div className="ourPurposeHeading">
            <div className="rectangle"></div>
            <p>Who Can Benefit?</p>
          </div>
          <ul>
            <li>
              <b>Students:</b> Prepare for academic and social challenges with
              confidence.
            </li>
            <li>
              <b>Professionals:</b> Enhance workplace interactions and career
              prospects.
            </li>
            <li>
              <b>Individuals:</b> Anyone looking to improve their personal and
              professional life.
            </li>
          </ul>
        </div>
      </div>
      <div className="dmitContactUsContainer">
        <h1>Get Started with Personality Development</h1>
        <div>
          <p>
            Begin your journey to becoming the best version of yourself with
            Personality Development training. Contact us today to schedule your
            session and consultation.
          </p>
          <img
            src="https://lifesignify.com/wp-content/uploads/2023/07/paths.svg"
            alt="contact us image"
          />
        </div>
        <button
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/get-a-consultation")}
        >
          Contact Us Now
        </button>
      </div>
    </div>
  );
};

export default PersonalityDevelopment;
