import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
      setIsLoggedIn(true);
    }
  }, []);

  const login = (id) => {
    setUserId(id);
    setIsLoggedIn(true);
    localStorage.setItem("userId", id);
  };

  const getUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      const response = await fetch(
        `https://api.lifesignify.com/api/auth/user/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      const data = await response.json();
      console.log(data);
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, [isLoggedIn]);

  const logout = () => {
    setUserId(null);
    setIsLoggedIn(false);
    localStorage.removeItem("userId");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, userId, login, logout, userData }}
    >
      {children}
    </AuthContext.Provider>
  );
};
