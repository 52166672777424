import React, { useEffect, useState } from "react";
import "../styles/Testimonial.css";
import axios from "axios";

const Testimonial = () => {
  const [testimonial, setTestimonials] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        "https://api.lifesignify.com/api/testimonial"
      );
      setTestimonials(response.data);
    } catch (error) {
      console.error("Failed to fetch testimonials", error);
    }
  };
  return (
    <div className="testimonial-container">
      <h1 className="testimonial-title">What Our Clients Say</h1>
      <div className="testimonial-grid">
        {testimonial.map((testimonial) => (
          <div key={testimonial._id} className="testimonial-card">
            <img
              src={testimonial.avatar}
              alt={testimonial.name}
              className="testimonial-avatar"
            />
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
